import React, {useState} from "react";

import ItemForm from "../FormItem";
import FormProgressBar from "../FormProgressBar";

const FormStart = ({setForm, formData, navigation, contentData, language}) => {

  const {nickName} = formData;
  const {next} = navigation;
  const content = contentData.startscreen;
  const [formStepIsValid, setFormStepIsValid] = useState(false);

  // check if formStep is valid
  if (!formStepIsValid) {
    if (nickName) {
      setFormStepIsValid(true);
    }
  } else {
    if (!nickName) {
      setFormStepIsValid(false);
    }
  }
  const triggerErrorNotification = () => {
    console.log('NED OIS AUSGFÜLLT!')
  }

  return (
    <>
      <div className="form">
        <FormProgressBar step="start"/>

        <div className="form__content">
          <div dangerouslySetInnerHTML={{__html: content.introText[language]}}/>
          <br/>

          <ItemForm
            label={content.textNickName[language]}
            name="nickName"
            value={nickName}
            onChange={setForm}
          />

          <div style={{display: 'flex', justifyContent: 'center', margin: '25px 0'}}>
            <button
              className={formStepIsValid ? 'form-navigation__next-button--validated' : 'form-navigation__next-button'}
              onClick={formStepIsValid ? () => {next(); window.scrollTo(0, 0);} : triggerErrorNotification}
            >
              {language === 'de' ? 'Weiter' : 'Next'}
            </button>
          </div>
        </div>

      </div>
    </>
  );
};

export default FormStart;
