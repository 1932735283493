import logo from './assets/img/main-logo-full.svg';
import monoLogo from './assets/img/mono-logo.svg';
import './assets/scss/app.scss';
import MemberWelcomeForm from "./MemberWelcomeForm";

function App() {
  return (
    <div className="app">
      <header className="app__header">
        <img src={logo} className="app__main-logo" alt="logo"/>
      </header>

      <main className="app__main-content">
        <MemberWelcomeForm/>
      </main>

      <footer className="app__footer">
        <div className="app__footer--credit">FSV Sportunion Augärtner Ultimate Graz | v1.2 2023</div>
        <div className="app__footer--links">
          <a href="https://www.augaertner-ultimate.at/impressum/" target="_blank" rel="noreferrer">
            Impressum
          </a>
          &nbsp;|&nbsp;
          <a href="https://www.augaertner-ultimate.at/" target="_blank" rel="noreferrer">
            augaertner-ultimate.at
          </a>
        </div>
      </footer>

      <img src={monoLogo} className="app__mono-logo" alt="mono-logo"/>
    </div>
  );
}

export default App;
