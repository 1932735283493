import React from "react";
import FormProgressBar from "../FormProgressBar";

const FormSubmit = ({navigationData, contentData, language}) => {
  const content = contentData.submit;

  if (navigationData.formSubmitResponse === 'error') {
    return (
      <div className="form">
        <FormProgressBar step="submit"/>

        <div className="form__content">
          <div dangerouslySetInnerHTML={{__html: content.error[language]}}/>
        </div>

      </div>
    );
  }

  return (
    <div className="form">
      <FormProgressBar step="submit"/>

      <div className="form__content">
        <div dangerouslySetInnerHTML={{__html: content.success[language]}}/>
      </div>

    </div>
  );
};

export default FormSubmit;
