import React, {useState} from "react";

import ItemForm from "../FormItem";
import FormNavigation from "../FormNavigation";
import FormProgressBar from "../FormProgressBar";
import FormNotification from "../FormNotification";
import '../../assets/scss/gender-select.scss';


const PersonalData = ({setForm, formData, navigation, navigationData, contentData, language}) => {
  const {firstName, lastName, nickName, birthday, email, phone, gender} = formData;
  const content = contentData.personal;
  const [formStepIsValid, setFormStepIsValid] = useState(false);

  // check if formStep is valid
  if (!formStepIsValid) {
    if (firstName && lastName && birthday && email && phone && gender) {
      setFormStepIsValid(true);
    }
  } else {
    if (!firstName || !lastName || !birthday || !email || !phone || !gender) {
      setFormStepIsValid(false);
    }
  }

  return (
    <div className="form">
      <FormProgressBar step="personal"/>

      <div className="form__content">

        Hallo, {nickName}!<br/>
        {content.introText[language]}

        <ItemForm
          label={language === 'de' ? 'Vorname' : 'First name'}
          name="firstName"
          value={firstName}
          onChange={setForm}
        />
        <ItemForm
          label={language === 'de' ? 'Nachname' : 'Last name'}
          name="lastName"
          value={lastName}
          onChange={setForm}
        />
        <ItemForm
          label={language === 'de' ? 'Geburtsdatum' : 'Birthdate'}
          name="birthday"
          value={birthday}
          onChange={setForm}
          type="date"
        />
        <ItemForm
          label={language === 'de' ? 'E-Mail-Adresse' : 'E-mail address'}
          name="email"
          value={email}
          onChange={setForm}
          type="email"
        />
        <ItemForm
          label={language === 'de' ? 'Telefon' : 'Phone number'}
          name="phone"
          value={phone}
          onChange={setForm}
          type="tel"
        />

        <br/>
        {language === 'de' ? 'Geschlecht' : 'Gender'}
        <br/>
        <p dangerouslySetInnerHTML={{__html:content.genderInfo[language]}}/>
        <div className={'gender-select'}>
          <label htmlFor={'gender-w'} className={gender === 'w' ? 'selected' : ''}>
            <input onChange={setForm} value={'w'} name={'gender'} type={'radio'} id={'gender-w'}
                   checked={gender === 'w'}/> {language === 'de' ? 'Weiblich' : 'female'}
          </label>
          <label htmlFor={'gender-m'} className={gender === 'm' ? 'selected' : ''}>
            <input onChange={setForm} value={'m'} name={'gender'} type={'radio'} id={'gender-m'}
                   checked={gender === 'm'}/> {language === 'de' ? 'Männlich' : 'male'}
          </label>
          <label htmlFor={'gender-x'} className={gender === 'x' ? 'selected' : ''}>
            <input onChange={setForm} value={'x'} name={'gender'} type={'radio'} id={'gender-x'}
                   checked={gender === 'x'}/> {language === 'de' ? 'Divers' : 'diverse'}
          </label>
        </div>
        <br/>
      </div>

      {navigationData.formSubmitResponse &&
        <FormNotification notification={navigationData.formSubmitResponse}/>
      }

      <FormNavigation {...{formData, navigation, navigationData, language, formStepIsValid}} />
    </div>
  );
};

export default PersonalData;
