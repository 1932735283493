import React from "react";
import MemberService from "../services/MemberService";

const FormNavigation = ({navigation, navigationData, language, formData = null, formStepIsValid = true}) => {

  const {previous, next, go} = navigation;
  const step = navigationData.step.id;

  const submitMemberData = () => {
    const memberService = new MemberService();
    memberService.sendNewMemberData(formData).then(
      (result) => {
        console.log(result);
        if (result.data === 'error') {
          navigationData.setFormSubmitResponse('error');
        } else {
          navigationData.setFormSubmitResponse('success');
        }
        go("submit");
      },
      (error) => {
        console.log(error);
        navigationData.setFormSubmitResponse('error');
      }
    )
  }

  const validateNumberSelection = () => {
    if (!formStepIsValid) {
      navigationData.setFormSubmitResponse(language === 'de' ? 'Bitte wähle eine Nummer, um fortzufahren.' : 'Please choose a number to proceed.');
      return;
    }
    navigationData.setFormSubmitResponse(null);
    next();
    window.scrollTo(0, 0);
  }

  const validatePersonalData = () => {
    if (!formStepIsValid) {
      navigationData.setFormSubmitResponse(language === 'de' ? 'Bitte fülle alle Felder aus, um fortzufahren.' : 'Please fill out all fields to proceed.');
      return;
    }
    console.log(formData.email);
    const memberService = new MemberService();
    memberService.validateMemberEmail(formData.email).then(
      (result) => {
        console.log(result);
        if (result.data === false) {
          navigationData.setFormSubmitResponse(language === 'de' ? 'Diese E-Mail-Adresse existiert bereits.' : 'This e-mail-address already exists.');
        } else {
          navigationData.setFormSubmitResponse(null);
          next();
          window.scrollTo(0, 0);
        }
      },
      (error) => {
        console.error(error);
        navigationData.setFormSubmitResponse(language === 'de' ? 'Diese E-Mail-Adresse existiert bereits.' : 'This e-mail-address already exists.');
      }
    )
  }

  const triggerErrorNotification = () => {
    navigationData.setFormSubmitResponse(language === 'de' ? 'Bitte fülle alle Felder aus, um fortzufahren.' : 'Please fill out all fields to proceed.');
  }

  if (step === 'start' || step === 'submit') {
    return;
  }

  if (step === 'review') {
    return (
      <nav className="form-navigation">
        <button className="form-navigation__previous-button"
                onClick={() => {previous(); window.scrollTo(0, 0);}}>{language === 'de' ? 'Zurück' : 'Back'}</button>
        <button
          className={formStepIsValid ? 'form-navigation__next-button--validated' : 'form-navigation__next-button'}
          onClick={submitMemberData}>{language === 'de' ? 'Abschicken' : 'Submit'}</button>
      </nav>
    );
  }

  if (step === 'number') {
    return (
      <nav className="form-navigation">
        <button className="form-navigation__previous-button"
                onClick={() => {previous(); window.scrollTo(0, 0);}}>{language === 'de' ? 'Zurück' : 'Back'}</button>
        <button
          className={formStepIsValid ? 'form-navigation__next-button--validated' : 'form-navigation__next-button'}
          onClick={validateNumberSelection}>{language === 'de' ? 'Weiter' : 'Next'}</button>
      </nav>
    );
  }

  if (step === 'personal') {
    return (
      <nav className="form-navigation">
        <button className="form-navigation__previous-button"
                onClick={() => {previous(); window.scrollTo(0, 0);}}>{language === 'de' ? 'Zurück' : 'Back'}</button>
        <button
          className={formStepIsValid ? 'form-navigation__next-button--validated' : 'form-navigation__next-button'}
          onClick={validatePersonalData}>{language === 'de' ? 'Weiter' : 'Next'}</button>
      </nav>
    );
  }

  return (
    <nav className="form-navigation">
      <button className="form-navigation__previous-button"
              onClick={() => {previous(); window.scrollTo(0, 0);}}>{language === 'de' ? 'Zurück' : 'Back'}</button>
      <button
        className={formStepIsValid ? 'form-navigation__next-button--validated' : 'form-navigation__next-button'}
        onClick={formStepIsValid ? () => {next(); window.scrollTo(0, 0);} : triggerErrorNotification}>{language === 'de' ? 'Weiter' : 'Next'}</button>
    </nav>
  );
}

export default FormNavigation;
